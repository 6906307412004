import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { ArticleContent, ArticleTitle } from "../components/article"

const ArticlesPage = () => (
  <Layout>
    <Seo title="Thèse" description="La thèse de Anne Marie Sow" />
    <div className="py-12 mt-8 flex flex-col items-center">
      <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />

      <h1 className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-900">Ma thèse</span>
      </h1>
      <div className="mt-12 px-4 sm:px-6 md:px-8">
        <div className="max-w-prose mx-auto space-y-12 lg:max-w-4xl">
          <article>
            <ArticleTitle
              type="Français"
              title="Scolarisation, inclusivité et qualité de vie des élèves atteints d’une maladie chronique invalidante au Sénégal. Le cas de la drépanocytose"
            />
            <ArticleContent>
              <p>
                La drépanocytose, maladie héréditaire chronique et invalidante, affecte gravement la vie de millions de personnes. Elle est caractérisée par de l’anémie, des infections fréquentes et des crises douloureuses. 66 % des 120 millions de personnes touchées dans le monde vivent en Afrique (OMS, 2022). Cette thèse, menée auprès de quatre cents élèves drépanocytaires au Sénégal, de leurs parents, enseignants et soignants, vise à comprendre la qualité de vie scolaire, familiale et hospitalière des drépanocytaires, et à proposer des solutions pour un meilleur accompagnement. Elle utilise la méthode mixte (questionnaires et entretiens) pour analyser les avancées et les obstacles à leur inclusion en tenant compte d’accords éthiques. Les résultats révèlent une faible connaissance de la drépanocytose par les personnes atteintes, ainsi que des pratiques familiales et scolaires inadaptées. Malgré quelques progrès au cours de la dernière décennie avec diverses initiatives pour promouvoir l'inclusivité, les interconnexions entre les catégories identitaires (âge, sexe, origine ethnique, etc.) et les conditions matérielles soulignent, dans une approche écosystémique, la complexité des défis : multiples formes de discrimination, obstacles à la scolarisation, nombreuses absences et échecs aux examens (25 %). Les violences physiques subies par 69 % des élèves, le décrochage scolaire fréquent (26 % chez les 13-18 ans), le coût élevé des soins médicaux et une faible littératie en santé continuent d'impacter négativement leur qualité de vie. L'intérêt majeur de cette recherche réside dans sa contribution significative à la compréhension de la scolarisation des élèves malades au Sénégal en examinant les interactions entre les environnements scolaire, familial et hospitalier. Elle propose des pistes et recommandations pour améliorer l'inclusivité.
              </p>
              <div>
                <a href="http://www.theses.fr/s346667">Voir sur theses.fr</a>
              </div>
            </ArticleContent>
          </article>
          <article>
            <ArticleTitle
              type="English"
              title="Schooling, inclusivity, and quality of life of students with chronic and disabling diseases in Senegal. The case of sickle-cell anaemia."
            />
            <ArticleContent>
              <p>
                Sickle cell disease, a hereditary chronic and disabling illness, severely affects the lives of millions of people. It is characterized by anemia, frequent infections, and painful crises. Sixty-six percent of the 120 million people affected worldwide live in Africa (WHO, 2022). This thesis, conducted with four hundred students with sickle cell disease in Senegal, their parents, teachers, and healthcare professionals, aims to understand the quality of life in school, family, and hospital settings for individuals suffering from sickle cell disease, and to propose solutions for better support. A mixed approach (questionnaires and interviews) is used to analyze the progress and challenges to their inclusion, taking ethical considerations into account. The results reveal a limited understanding of sickle cell disease by those affected, as well as inappropriate family and school practices. Despite some progress over the past decade with various initiatives to promote the inclusion of those affected, identified factors (age, sex, ethnic origin, etc.) and diversity of material circumstance increase the challenges any ecosystemic approach must address multiple forms of discrimination, barriers to schooling, numerous absences, and exam failures (25%). The physical violence experienced by 69 % of students, the high dropout rate (26% among 13- to 18-year-olds), the high cost of medical care, and low health literacy continue to negatively impact their quality of life. The primary value of this research lies in its significant contribution to understanding the schooling of sick children in Senegal by examining the interactions between school, family, and hospital environments. Based on the findings, the thesis proposes recommendations to improve social and educational inclusivity.              </p>
              <div>
                <a href="http://www.theses.fr/s346667">Voir sur theses.fr</a>
              </div>
            </ArticleContent>
          </article>

        </div>
      </div>
    </div>
  </Layout>
);
export default ArticlesPage;
